import { Context, createContext } from 'react';
import { globalScope } from './global';

export let mobxStoresContext: Context<{ [index: string]: any }>;

if (globalScope.__LARDY__CONTEXT__MOBX_STORES__ === undefined) {
    globalScope.__LARDY__CONTEXT__MOBX_STORES__ = createContext<{ [index: string]: any }>({});
}

mobxStoresContext = globalScope.__LARDY__CONTEXT__MOBX_STORES__;

import { ComponentType, createElement } from 'react';
import { IBuiltins, IElementTransformGetElementProps } from '../../interfaces';
import { getElementContext } from '../context/getElement';
import { compose } from '../decorators';
import { truncateDisplayName } from '../helpers';

export const withElementTransforms = (
    rootTransform: IBuiltins['elementRootTransform'],
    transforms: IBuiltins['elementTransforms']
) => {
    return <P>(Component: ComponentType<P>) => {

        const getElement = ({ element, name, root }: IElementTransformGetElementProps) => compose(
            ...transforms.map((transform) => transform({ element, name }))
        )(root || rootTransform({ element, name }));

        const WithElementTransforms = (props: P) => {
            return createElement(getElementContext.Provider, {
                value: getElement
             }, createElement(Component, props));
        };

        (WithElementTransforms as any).displayName = truncateDisplayName(
            Component.displayName,
            'WithElementTransforms'
        );

        return WithElementTransforms;
    };
};

export const truncateDisplayName = (displayName: string | undefined, hocName: string) => {
    displayName = displayName || 'Unknown';

    const startIndex = displayName.lastIndexOf('(');
    const endIndex = displayName.indexOf(')');

    if (startIndex === -1 || endIndex === -1) {
        return `${hocName}(${displayName})`;
    }

    const innerName = displayName.slice(startIndex + 1, endIndex);

    return `${hocName}(...(${innerName}))`;
};

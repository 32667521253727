import { createElement, forwardRef, HTMLProps } from 'react';
// import { truncateDisplayName } from './truncateDisplayName';

export const elementComponent = (
    element: string
) => {
    const ElementComponent = forwardRef<HTMLElement, HTMLProps<any>>(
        (
            props,
            ref
        ) => createElement(
            element,
            {
                ...props,
                ref
            }
        )
    );

    return ElementComponent;
};

import { observer } from 'mobx-react-lite';
import { createElement, useContext } from 'react';
import { IMiddlewareDecorator, IMiddlewareHook, IMiddlewareProvider } from '../../interfaces';
import { middlewareHooksContext } from '../context/middlewareHooks';

export class InjectionMiddleware<N extends string, T> {
    private name: N;
    private storeName: string;
    private decorator: IMiddlewareDecorator<T>;
    private hooks: Array<IMiddlewareHook<any, T[keyof T], T>> = [];

    public constructor(name: N, decorator: IMiddlewareDecorator<T>) {
        this.name = name;
        this.storeName = `$vrc-middleware-${this.name}`;
        this.decorator = decorator;
    }

    public provider = (...opts: any[]) => {
        const Component: any = this.decorator(...opts)(this.getProvider);

        Component.__middlewareRole = 'provider';

        return Component as IMiddlewareProvider<any>;
    }

    public hook = <I extends string, K extends keyof T>(name: I, key: K) => {
        const hook: IMiddlewareHook<I, T[K], T> = {
            id: `${this.name}__${key}`,
            key: key as string,
            name,
            getMiddleware: () => this,
            __type: (undefined as unknown) as T[K],
            __middlewareRole: 'hook'
        };

        this.hooks.push(hook);

        return hook;
    }

    public getStoreName = () => this.storeName;

    private getProvider = (store: T) => observer((props: any) => {
        const hookContext = useContext(middlewareHooksContext);

        const stores = {
            ...hookContext,
            [this.storeName]: store
        };

        return createElement(middlewareHooksContext.Provider, {
            ...props,
            value: stores
        });
    })
}

export const createInjectionMiddleware = <T>(name: string, decorator: IMiddlewareDecorator<T>) => {
    return new InjectionMiddleware<string, T>(name, decorator);
};

import { observer } from 'mobx-react-lite';
import { ComponentType, createElement, useMemo, useState } from 'react';
import { IStateWrappedUpdaters } from '../../interfaces';
import { truncateDisplayName } from '../helpers';

export const withStateHandlers = (
    initialState: {
        [index: string]: any;
    },
    stateUpdaters: IStateWrappedUpdaters<any>
) => {
    return <P>(Component: ComponentType<P>) => {
        const WithStateHandlers = observer((props: P) => {
            const [state, setState] = useState(initialState);

            const handlers = useMemo(() => Object.keys(stateUpdaters).reduce((handlers, key) => ({
                ...handlers,
                [key]: (...payload: any[]) => setState(
                    (state: any, props: any) => stateUpdaters[key](state, props)(...payload)
                )
            }), {} as { [index: string]: (...payload: any[]) => void }), []);

            return createElement(Component, {
                ...(props as any),
                ...state,
                ...handlers
            });
        });

        WithStateHandlers.displayName = truncateDisplayName(
            Component.displayName,
            'WithStateHandlers'
        );

        return WithStateHandlers;
    };
};

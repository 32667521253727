import { ComponentType, Context, createContext } from 'react';
import { IElementTransformGetElementProps } from '../../interfaces';
import { globalScope } from './global';

export let getElementContext: Context<((props: IElementTransformGetElementProps) => ComponentType<any>) | undefined>;

if (globalScope.__LARDY__CONTEXT__GET_ELEMENT__ === undefined) {
    globalScope.__LARDY__CONTEXT__GET_ELEMENT__ = createContext<
        ((props: IElementTransformGetElementProps) => ComponentType<any>) | undefined
    >(undefined);
}

getElementContext = globalScope.__LARDY__CONTEXT__GET_ELEMENT__;

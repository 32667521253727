
import { observer } from 'mobx-react-lite';
import { Validator } from 'prop-types';
import * as React from 'react';
import {
    IComponent, IComponentConstructor, ICompositeComponent, IDecoratorFunction,
    IHybridHandlers, IMiddlewareConsumable,
    isMiddlewareHook, isMiddlewareProvider,
    IStateWrappedUpdaters, IType, ResolvedExports,
    TComponent
} from '../../interfaces';
import * as decorators from '../decorators';
import { ApiComponent } from './apiComponent';

export class Component extends ApiComponent implements ICompositeComponent {

    private decorators: IDecoratorFunction[] = [];

    public constructor(
        name: string,
        options?: {
            decorators?: IDecoratorFunction[],
            injectors?: { [index: string]: string },
            renderFn?: (props: any) => React.ReactElement<any> | null
        }
    ) {
        super();

        this.name = name;

        if (options === undefined) {
            return;
        }

        if (options.decorators !== undefined) {
            this.decorators = options.decorators;
        }

        if (options.injectors !== undefined) {
            this.injectors = options.injectors;
        }

        if (options.renderFn !== undefined) {
            this.renderFn = options.renderFn;
        }
    }

    public renderFn: (props: any) => React.ReactElement<any> | null = () => null;

    public compile() {
        const Comp = observer(this.renderFn);

        (Comp as any).displayName = this.name;

        return decorators.compose(
            ...this.provideBuiltinStores(),
            ...this.injectMobxStores(),
            ...this.extractMiddlewareHooks(),
            ...this.decorators,
            ...this.provideMobxStores()
        )(Comp);
    }

    public decorate<XIP = {}, XS = {}, XOP = {}>(decorator: IDecoratorFunction): TComponent {
        this.decorators.push(decorator);

        return this;
    }

    public extends<T extends IComponent<any, any, any, any, any, any>>(component: T) {
        return (new Component(this.name, component.extract()) as unknown) as T;
    }

    public export() {
        const userspaceExports = Object.keys(this.exports).reduce((resolved, key) => ({
            ...resolved,
            [key]: this.exports[key](this as any)
        }), {});

        const name = this.getName();
        const Component = this.compile();

        return {
            ...userspaceExports,
            [name]: Component,
            default: Component
        } as ResolvedExports<any, any>;
    }

    public extract() {
        return {
            decorators: this.decorators,
            injectors: this.injectors,
            renderFn: this.renderFn
        };
    }

    public hybrid<T extends { [index: string]: IType<any> }, U extends IHybridHandlers<T>>(props: T, handlers: U) {
        return this.decorate(decorators.hybrid(props, handlers));
    }

    public inject(...args: any[]): TComponent {
        if (args.length === 1) {
            const injectMap = args[0];

            this.injectors = {
                ...this.injectors,
                ...Object.keys(injectMap).reduce((data, key) => ({
                    ...data,
                    [key]: injectMap[key]
                }), {})
            };
        } else if (args.length === 2) {
            const [identifier, propName] = args;

            this.injectors[propName] = identifier;
        } else {
            throw new Error('Illegal parameter count: inject');
        }

        return this;
    }

    public props<Q extends { [index: string]: IType<any> }>(props: Q) {
        const propTypes = Object.keys(props).reduce((map, key) => ({
            ...map,
            [key]: props[key].propType
        }), {} as { [index: string]: Validator<any> });

        return this.decorate(decorators.setPropTypes(propTypes));
    }

    public provide(...args: any[]): TComponent {
        if (args.length === 1) {
            const injectMap = args[0];

            this.providers = {
                ...this.providers,
                ...Object.keys(injectMap).reduce((data, key) => ({
                    ...data,
                    [key]: injectMap[key]
                }), {})
            };
        } else if (args.length === 2) {
            const [propName, identifier] = args;

            this.providers[propName] = identifier;
        } else {
            throw new Error('Illegal parameter count: provide');
        }

        return this;
    }

    public reduce<XIP>(reducer: (props: any) => XIP) {
        return this.decorate(decorators.withReducer(reducer));
    }

    public render(fn: (props: any) => React.ReactElement<any> | null): TComponent {
        this.renderFn = fn;

        return this;
    }

    public state<T extends { [index: string]: IType<any> }, U extends IStateWrappedUpdaters<T>>(state: T, handlers: U) {
        const initialState = Object.keys(state).reduce((initialState, key) => {
            const type = state[key];

            if (type.value !== undefined) {
                initialState[key] = type.value;
            }

            return initialState;
        }, {} as any);

        return this.decorate(
            decorators.withStateHandlers(initialState, handlers)
        );
    }

    public use(
        consumable: IMiddlewareConsumable
    ): TComponent {
        if (isMiddlewareHook(consumable)) {
            this.hooks.push(consumable);
        } else if (isMiddlewareProvider(consumable)) {
            this.decorate(consumable);
        } else {
            const extension = consumable(this);

            let exports = Object.keys(extension.__proto__).filter((key) => key !== 'constructor');

            if (extension.exports !== undefined) {
                exports = extension.exports;
            }

            for (const i of exports) {
                (this as any)[i] = extension.__proto__[i].bind(extension);
            }
        }

        return this;
    }
}

export const component: IComponentConstructor<React.PropsWithChildren<{}>> = <N extends string>(name: N) => {
    return (new Component(name) as unknown) as IComponent<
        React.PropsWithChildren<{}>,
        'Base',
        React.PropsWithChildren<{}>,
        any,
        any,
        {
            __defaultName: N
        }
    >;
};

import { observer } from 'mobx-react-lite';
import { ComponentType, createElement, SFC, useContext } from 'react';
import { IMiddlewareHook } from '../../interfaces';
import { middlewareHooksContext } from '../context/middlewareHooks';
import { truncateDisplayName } from '../helpers';

export const withMiddlewareExtractor = (hooks: Array<IMiddlewareHook<any, any, any>>) => {
    return <P>(Component: ComponentType<P>) => {

        const WithMiddlewareExtractor = observer((props: P) => {
            const hookStores = useContext(middlewareHooksContext);

            const hookProps = hooks.reduce((data, hook) => {
                const storeName = hook.getMiddleware().getStoreName();

                const store = hookStores[storeName];

                if (store === undefined) {
                    return data;
                }

                data[hook.name] = store[hook.key];

                return data;
            }, {} as { [index: string]: any });

            const otherProps = Object.keys(props).filter((key) => {
                return !hooks.find((hook) => hook.getMiddleware().getStoreName() === key);
            }).reduce((p, key) => ({ ...p, [key]: props[key] }), {});

            return createElement(Component as SFC, { ...otherProps, ...hookProps });
        });

        (WithMiddlewareExtractor as any).displayName = truncateDisplayName(
            Component.displayName,
            'WithMiddlewareExtractor'
        );

        return WithMiddlewareExtractor;
    };
};

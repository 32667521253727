import { observer } from 'mobx-react-lite';
import { ComponentType, createElement, SFC, useContext } from 'react';
import { IPropInjectionMap } from '../../interfaces';
import { mobxStoresContext } from '../context/mobxStores';
import { truncateDisplayName } from '../helpers';

export const withMobxProvider = <T, P extends IPropInjectionMap<T>>(map: P) => {
    return <IP>(Component: ComponentType<IP>) => {

        const WithMobxProvider = observer((props: P) => {
            const mappedInjectables = Object.keys(map).reduce((provide, key) => ({
                ...provide,
                [key]: props[key]
            }), {});

            const stores = {
                ...useContext(mobxStoresContext),
                ...mappedInjectables
            };

            return createElement(mobxStoresContext.Provider, {
                value: stores
            }, createElement(Component as SFC, props));
        });

        (WithMobxProvider as any).displayName = truncateDisplayName(
            Component.displayName,
            'WithMobxProvider'
        );

        return WithMobxProvider;
    };
};

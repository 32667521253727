import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { truncateDisplayName } from '../helpers';

export const withReducer = <P, XP>(reduceProps: (props: P) => XP) => (Component: React.ComponentType<XP>) => {
    const WithReducer = observer((props: P) => React.createElement(Component, reduceProps(props)));
    WithReducer.displayName = truncateDisplayName(Component.displayName, 'WithReducer');

    return WithReducer;
};

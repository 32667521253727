import { Context, createContext } from 'react';
import { globalScope } from './global';

export let middlewareHooksContext: Context<{ [index: string]: any }>;

if (globalScope.__LARDY__CONTEXT__MIDDLEWARE_HOOKS__ === undefined) {
    globalScope.__LARDY__CONTEXT__MIDDLEWARE_HOOKS__ = createContext<{ [index: string]: any }>({});
}

middlewareHooksContext = globalScope.__LARDY__CONTEXT__MIDDLEWARE_HOOKS__;

import { observer } from 'mobx-react-lite';
import { ComponentType, createElement, useContext } from 'react';
import { IBuiltins, IElementTransformGetElementProps } from '../../interfaces';
import { getElementContext } from '../context/getElement';
import { getElementComposer, truncateDisplayName } from '../helpers';

export interface IElementIndexProps {
    getElement: (props: IElementTransformGetElementProps) => ComponentType<any>;
    [index: string]: any;
}

export const withElementIndex = (elementIndex: IBuiltins['elementIndex']) => {
    return <P>(Component: ComponentType<P>) => {
        const indexedElements = {};

        const WithElementIndex = observer((props: P & IElementIndexProps) => {
            const getElement = useContext(getElementContext)!;

            const $ = getElementComposer(getElement, indexedElements);

            elementIndex($);

            return createElement(Component, { $, ...(props as any) });
        });

        (WithElementIndex as any).displayName = truncateDisplayName(
            Component.displayName,
            'WithElementIndex'
        );

        return WithElementIndex;
    };
};

import { ComponentType, createElement, useState } from 'react';
import { IDecoratorFunction, IHybridHandlers, IType } from '../../interfaces';
import { truncateDisplayName } from '../helpers';

export function hybrid<T extends { [index: string]: IType<any> }>(propMap: T, handlers: IHybridHandlers<T>) {
    return (<P extends { [index: string]: any }>(Component: ComponentType<P>) => {
        const initialHState = Object.keys(propMap).reduce((store, key) => {
            store[key] = propMap[key].value;

            return store;
        }, {} as { [index: string]: any });

        const Hybrid = (props: P) => {
            const [hState, setHState] = useState(initialHState);

            const hHandlers = Object.keys(handlers).reduce((hHandlers, key) => ({
                ...hHandlers,
                [handlers[key]]: (value: any) => {
                    if (props[handlers[key]] === undefined) {
                        setHState({ ...hState, [key]: value });
                    } else {
                        props[handlers[key]](value);
                    }
                }
            }), {} as { [index: string]: any });

            return createElement(Component, { ...hState, ...(props as any), ...hHandlers });
        };

        (Hybrid as any).displayName = truncateDisplayName(Component.displayName, 'Hybrid');

        return Hybrid;
    }) as IDecoratorFunction;
}

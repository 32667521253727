import { ComponentType } from 'react';
import { IComponent } from './component';
import { IExtension } from './extension';

export interface IMiddleware<T> {
    provider(opts: any): <P>(Component: ComponentType<P>) => ComponentType<P>;
    hook<I extends string, K extends keyof T>(name: I, key: K): IMiddlewareHook<I, T[K], T>;
    getStoreName(): string;
}

export interface IMiddlewareHook<I extends string, T, U> {
    id: string;
    name: I;
    key: string;
    getMiddleware: () => IMiddleware<U>;
    __type: T;
    __middlewareRole: 'hook';
}

export type IMiddlewareGetProviderFn<T> = (store: T) => ComponentType<T>;

export type IMiddlewareDecorator<T> = (...opts: any[]) => (getProvider: IMiddlewareGetProviderFn<T>) =>
    <P>(Component: ComponentType<P>) => ComponentType<P>;

export type IMiddlewareRawExtender = (composer: IComponent<any, any, any, any, any, any>) => IExtension;

export type IMiddlewareExtender<X extends IMiddlewareRawExtender> = X
& {
    __middlewareRole: 'extender';
};

export interface IMiddlewareExtension<C extends IComponent<any, any, any, any, any, any>> {
    [index: string]: (...payload: any[]) => C;
}

export interface IMiddlewareProvider<P> {
    (Component: ComponentType<P>): ComponentType<P>;
    __middlewareRole: 'provider';
}

export type IMiddlewareConsumable = IMiddlewareHook<any, any, any>
    | IMiddlewareProvider<any>
    | IMiddlewareExtender<any>;

export function isMiddlewareHook(
    consumable: IMiddlewareConsumable
): consumable is IMiddlewareHook<any, any, any> {
    return consumable.__middlewareRole === 'hook';
}

export function isMiddlewareProvider(
    consumable: IMiddlewareConsumable
): consumable is IMiddlewareProvider<any> {
    return consumable.__middlewareRole === 'provider';
}

export function isMiddlewareExtender(
    consumable: IMiddlewareConsumable
): consumable is IMiddlewareExtender<any> {
    return consumable.__middlewareRole === 'extender';
}

import { observer } from 'mobx-react-lite';
import { ComponentType, createElement, useContext } from 'react';
import { mobxStoresContext } from '../context/mobxStores';
import { truncateDisplayName } from '../helpers';

export const withMobxInjector = (map: { [index: string]: string }) => {
    return <P>(Component: ComponentType<P>) => {
        const WithMobxInjector = observer((props: P) => {
            const stores = useContext(mobxStoresContext);

            const injectProps = Object.keys(map).reduce((data, key) => ({
                ...data,
                [map[key]]: stores[key]
            }), {} as { [index: string]: any });

            return createElement(Component, { ...(props as any), ...injectProps });
        });

        (WithMobxInjector as any).displayName = truncateDisplayName(
            Component.displayName,
            'WithMobxInjector'
        );

        return WithMobxInjector;
    };
};

import { ComponentType, ForwardRefExoticComponent } from 'react';
import * as uuid from 'uuid';
import { IElementComposerFn, IElementTransformGetElementProps } from '../../interfaces';
import { truncateDisplayName } from './truncateDisplayName';

export interface IElementComposerIndexedElements {
    [index: string]: IElementComposerIndexedElementsByType;
}

export interface IElementComposerIndexedElementsByType {
    [index: string]: ComponentType<any>;
}

export function getElementComposer(
    getElement: (props: IElementTransformGetElementProps) => ComponentType<any>,
    indexedElements: IElementComposerIndexedElements
) {
    const $: IElementComposerFn<any, any> = (Component, name: string) => {
        let element = 'div';
        let componentName: string;
        let id: string;
        let root: ComponentType | ForwardRefExoticComponent<any> | undefined;

        if (typeof Component === 'string') {
            element = id = componentName = Component;
        } else {
            (Component as any).__$vrc__composerId = (Component as any).__$vrc__composerId || uuid.v4();

            id = (Component as any).__$vrc__composerId as string;
            componentName = (Component as any).displayName || id;
            root = Component;
        }

        if (indexedElements[id] === undefined) {
            indexedElements[id] = {};
        }

        const indexedByComponent = indexedElements[id];

        if (indexedByComponent[name] !== undefined) {
            return indexedByComponent[name];
        }

        const Element = getElement({
            element,
            name,
            root
        });

        Element.displayName = truncateDisplayName(
            componentName,
            '$'
        );

        indexedByComponent[name] = Element;

        return Element;
    };

    return $;
}

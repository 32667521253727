import { ICompositeComponent } from '../../interfaces';

export class Extension {
    private self: ICompositeComponent;

    public constructor(self: ICompositeComponent) {
        this.self = self;
    }

    public getSelf(): ICompositeComponent {
        return this.self;
    }
}

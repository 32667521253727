import * as htmlElements from '../../data/htmlElementNames.json';
import {
    Export,
    IApiComponent,
    IBuiltins,
    IExports,
    IMiddlewareHook,
    TComponent
} from '../../interfaces';
import * as decorators from '../decorators';
import { elementComponent } from '../helpers';

export class ApiComponent implements IApiComponent {
    public name: string;
    public hooks: Array<IMiddlewareHook<any, any, any>> = [];
    public injectors: { [index: string]: string } = {};
    public providers: { [index: string]: string } = {};
    public styleNames: string[] = [];

    public _props: any;
    public _style: any;
    public _outerProps: any;
    public _extension: any;
    public _hextension: any;
    public _enrichedStyle: any;
    public _exports: any;

    public _builtins: IBuiltins = {
        elementRootTransform: ({ element }) => elementComponent(element),
        elementTransforms: [],
        elementIndex: ($) => htmlElements.forEach((key) => {
            Object.defineProperty($, key, {
                get() {
                    return {
                        Base: $(key, 'Base')
                    };
                }
            });
        }, {})
    };

    public exports: IExports = {};

    public addExport<N extends string, G extends Export>(
        name: N,
        getter: G
    ) {
        this.exports[name] = getter;

        return (this as unknown) as TComponent;
    }

    public define<K extends keyof IBuiltins>(
        key: K,
        val: IBuiltins[K] extends any[] ? IBuiltins[K][number] : IBuiltins[K]
    ) {
        const builtin = this._builtins[key];

        if (Array.isArray(builtin)) {
            builtin.push(val);
        } else {
            this._builtins[key] = val;
        }

        return (this as unknown) as TComponent;
    }

    public getName() {
        return this.name;
    }

    public extractMiddlewareHooks = () => {
        if (this.hooks.length === 0) {
            return [];
        }

        return [
            decorators.withMiddlewareExtractor(this.hooks)
        ];
    }

    public injectMobxStores = () => {
        if (Object.keys(this.injectors).length === 0) {
            return [];
        }

        return [
            decorators.withMobxInjector(this.injectors)
        ];
    }

    public provideBuiltinStores = () => {
        return [
            decorators.withElementTransforms(
                this._builtins.elementRootTransform,
                this._builtins.elementTransforms
            ),
            decorators.withElementIndex(
                this._builtins.elementIndex
            )
        ];
    }

    public provideMobxStores = () => {
        if (Object.keys(this.providers).length === 0) {
            return [];
        }

        return [
            decorators.withMobxProvider(this.providers)
        ];
    }
}

import * as propTypes from 'prop-types';
import { IType } from '../interfaces';

namespace types {
    export class CType<T> implements IType<T> {

        public get propType() {
            const propType = this.requireable;

            if (this.isRequired) {
                return propType.isRequired;
            }

            return propType;
        }

        public isRequired: boolean = false;
        public requireable: propTypes.Requireable<any>;
        public value: T;
        public constructor(requireable: propTypes.Requireable<any>, isRequired: boolean, value?: T) {
            this.requireable = requireable;
            this.isRequired = isRequired;

            if (value !== undefined) {
                this.value = value;
            }
        }

        public withDefault(val: T) {
            return new CType<T>(this.requireable, this.isRequired, val);
        }
    }

    export const bool = new CType<boolean>(propTypes.bool, true);
    export const num = new CType<number>(propTypes.number, true);
    export const str = new CType<string>(propTypes.string, true);
    export const fn = <F extends (...args: any[]) => void = () => void>() => new CType<F>(propTypes.func, true);
    export const complex = <T = any>() => new CType<T>(propTypes.any, true);
    export const arrayOf = <T extends CType<any>>(t: T) => new CType<Array<T['value']>>
        (propTypes.arrayOf(t.requireable), true);
    export const maybe = <T extends CType<any>>(t: T) => new CType<T['value'] | undefined>(t.requireable, false);
}

export = types;
